.card {
  border-radius: 10px;
  padding: 34px;
  text-align: center;
  width: 330px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: grid !important;
  gap: 10px;
  background-color: white;
}
.card img {
  width: 150px;
  margin: 0px auto;
}
.card .text_hlight {
  color: #5e6ff9;
}
.card span {
  font-size: 24px;
  font-weight: bold;
  margin-right: 5px;
}
.carousel_wrapper {
  position: relative;
  /* left: 10px; */
  background-image: url("/src/components/assets/images/bg2.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

/* .center .heading {
    opacity: 0.8;
    transition: all 300ms ease;
  }
  .content {
    padding: 20px;
    margin: auto;
    width: 90%;
  }

  @media (max-width: 768px) {
    .heading {
      font-size: 24px;
    }
    .center {
      margin-left: -40px;
      margin-right: -40px;
    }
    .center .heading {
      opacity: 0.8;
      transform: scale(0.95);
      transition: all 300ms ease;
    }
  } */
