@charset "UTF-8";

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
h4 {
  font-size: 15px !important;
  font-weight: inherit;
}
h3 {
  font-size: 18px !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  /* font-weight: 700 !important; */
  line-height: 1.2;
}

nav.menu {
  flex-grow: 1;
}
.header-wrapper {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #e6e6e6;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 1rem;
  /* max-width: 1174px; */
  position: relative;

  /* margin-left: auto;
    margin-right: auto; */
  margin: 0 auto;
}
img.brand-icon {
  max-height: 3.2rem;
  cursor: pointer;
  /* margin-right: 2rem; */
  width: auto;
}
.menu ol {
  list-style: none;
  /* padding: 0 0 12px 00; */
  margin: 0;
}

.menu > ol > li.menu-item {
  font-size: 16px;
  font-weight: 600;
}

.menu ol:first-child {
  width: 100%;
  /* max-width: 960px; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 32px;
  position: relative;
  transition: background 0.3s ease-in-out;
}

.menu-item a,
span.nav-bottom-line,
span.nav--heading {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  color: rgb(52, 79, 113);
  background-color: transparent;
  letter-spacing: 0.7px;
  text-decoration: none;
  /* height: 100%; */
  width: 100%;
  position: relative;
}

.menu-item a.nav-bottom-line:hover,
.menu-item span.nav-bottom-line:hover,
.menu-item span.nav--heading:hover {
  background-color: none;
  color: #0089ff;
}
.menu-item a:hover:not(.nav-bottom-line),
.menu-item a:hover:not(.nav-bottom-line) h4 {
  color: #0089ff;
}
span.nav-bottom-line {
  display: inline-block;
  cursor: pointer;
}
span.nav--heading {
  text-align: left;
  display: inline-block;
  cursor: pointer;
}

.menu-item a.nav-bottom-line::after,
.menu-item span.nav-bottom-line::after {
  position: absolute;
  content: "";
  width: inherit;
  height: 4px;
  border-radius: 5px;
  background: #3498db;
  left: 0;
  bottom: -16px;
  opacity: 0;
}

.menu-item a.nav-bottom-line:hover::after,
.menu-item span.nav-bottom-line:hover::after {
  transition: 0.4s;
  opacity: 1;
}
/* .menu-item > a:hover .sub-menu {
  top: 48px;
  opacity: 1;
} */
.menu-item:nth-child(1) > a::before {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 1rem;
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #16a085;
}
.sub-menu > li > a {
  color: #687992;
}

.drop-main {
  display: flex;
  position: absolute;
  background-color: rgb(255, 255, 255);
  top: 100%;
  width: 430px;
  justify-content: center;
  border-radius: 8px;
  box-shadow: rgba(0, 18, 64, 0.15) 0px 2px 5px;

  transform-origin: top;
  transform: rotateX(-90deg);
  transition: transform 0.3s linear;
  z-index: 2;
}
.drop-left,
.drop-right {
  padding: 32px 32px 32px 40px;
}
.description {
  text-align: left;
  font-weight: normal;
  margin-bottom: 15px;
  line-height: 19px;
}
.drop-left-heading,
.drop-right-heading {
  color: darkslategray;
  line-height: 1em;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 18px;
  font-size: 14px;
  margin-top: 0px;
}
.drop-left-list-links,
.drop-right-list-links {
  list-style-type: none;
  list-style-position: outside;
  color: #2c3645;
  padding: 0;
}
/* .drop-left-list-links li,
.drop-right-list-links li{
  width: 50%;
margin-bottom: 16px;
min-width: 252px;
padding: 8px 12px;
} */

.drop-right {
  padding: 32px 32px 32px 40px;
  /* min-width: 240px; */
  background-color: white;
  /* background: rgb(241, 243, 248) none repeat scroll 0% 0%; */
}

.sub-main-div {
  width: 349px;
  height: 395px;
  position: absolute;
  top: 0px;
  left: 100%;
  transform: rotateX(-90deg);
  background-color: white;
  border-radius: 0 12px 12px 0;
  box-shadow: rgba(0, 18, 64, 0.15) 7px 2px 5px;
  transition: 0.3s;
  border-left: 1px solid #5e6ff9;
  display: flex;
  padding: 1rem;
  padding-top: 1.7rem;
}
.sub-main-div2 a {
  align-items: center;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  gap: 7px;
}
.sub-main-div2 {
  width: 700px;
  height: 21rem;
  position: absolute;
  transform-origin: top;
  top: 49px;
  z-index: 4;
  /* left: 100%; */
  transform: rotateX(-90deg);
  background-color: white;
  border-radius: 12px 12px 12px 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  /* box-shadow: rgba(0, 18, 64, 0.15) 7px 2px 5px; */

  transition: 0.3s;
  display: flex;
  padding: 2rem;
  gap: 14px;
  left: -3%;
}

.Integrations-div {
  /* padding-left: 10px; */
  /* border-left: 1px solid blue; */
  flex: 1;
}

.Integrations-div .crm-doc-wrapper {
  display: flex;
}

.Integrations-div a img {
  width: 104px;
  border-radius: 0;
  box-shadow: none;
}

.Integrations-div > p {
  letter-spacing: 0.7px;
}

.superSubMenu p {
  font-size: 14px;
  margin-bottom: 0px;
}
.superSubMenu p:nth-child(2) {
  font-size: 12px;
  margin-bottom: 0px;
}

.left-div,
.right-div {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  flex: 1;
  color: white;
}

.sub-main-div .left-div {
  background-color: white;
  /* padding: 20px 0px; */
}
.sub-main-div .right-div {
  min-width: 240px;
  /* padding: 20px; */
  border-radius: 0 12px 12px 0;
}
.sub-main-div a {
  height: fit-content;
  align-items: center;
  padding-left: 20px;
  height: fit-content;
  display: flex;
  flex-direction: row;
}
.material-icons {
  position: absolute;
  top: 23%;
  left: 100%;
  color: rgb(52, 79, 113);
  font-size: 19px;
}
.sub-material-icons {
  transform: rotate(-90deg);
  left: 70%;
  top: 17px;
  display: none;
  /* margin-top: 32px; */
}
.drop-icon-flex {
  position: relative;
  padding: 9px;
}
.header-wrapper .login-container {
  display: flex;
  gap: 2rem;
  justify-content: right;
  margin-top: 2px;
  color: #5e6ff9;
  position: absolute;
  right: 18%;
  top: 12%;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none !important;
}
.developer_section {
  display: flex;
}
.login-container a {
  text-decoration: none;
  color: #5e6ff9 !important;
}
.login_container_mobile {
  display: none;
}
.superSubMenu img {
  width: 37px;
  margin: "0 8px";
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 50%;
}
.header-wrapper .login-container span {
  cursor: pointer;
}

.sub-menu > li img {
  width: 37px;
  margin-right: 9px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .menu-item:nth-child(1) > a::before {
    font-size: 1.5rem;
  }
}
.menu-item:nth-child(2) > a::before {
  /* font-family: "Font Awesome 5 Free"; */
  font-size: 1.2rem;
  display: block;
  margin-bottom: 1rem;
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #16a085;
}
@media (min-width: 768px) {
  .menu-item:nth-child(2) > a::before {
    font-size: 1.5rem;
  }
}
.menu-item:nth-child(3) > a::before {
  font-family: "Font Awesome 5 Free";
  font-size: 1.2rem;
  display: block;
  margin-bottom: 1rem;
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #16a085;
}
@media (min-width: 768px) {
  .menu-item:nth-child(3) > a::before {
    font-size: 1.5rem;
  }
}
.menu-item:nth-child(4) > a::before {
  font-family: "Font Awesome 5 Free";
  font-size: 1.2rem;
  display: block;
  margin-bottom: 1rem;
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #16a085;
}
@media (min-width: 768px) {
  .menu-item:nth-child(4) > a::before {
    font-size: 1.5rem;
  }
  .right-div {
    border-right: 1px solid blue;
    padding-right: 10px;
  }
}

.menu-item:nth-child(5) > a::before {
  font-family: "Font Awesome 5 Free";
  font-size: 1.2rem;
  display: block;
  margin-bottom: 1rem;
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #16a085;
}

@media (min-width: 768px) {
  .menu-item:nth-child(5) > a::before {
    font-size: 1.5rem;
  }
}
.menu-item .sub-menu > a {
  color: red;
}
.menu-item ol.sub-menu {
  position: absolute;
  top: 3rem;
  transform-origin: top;

  transform: rotateX(-90deg);
  transition: transform 0.3s ease;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 2;
  width: max-content;
  padding: 1rem 3rem 2rem 1.5rem;
  line-height: 44px;
  border-radius: 12px 0px 0px 12px;
  left: -7px;
}

.first-sub-menu {
  height: 395px;
}

.menu-item .sub-menu .menu-item {
  text-align: left;
}

.menu-item .sub-menu .menu-item:first-child {
  border: 0;
}
/* .menu-item:hover {
  background-color: rgba(255, 255, 255, 0.15);
} */
/* .menu-item:hover a::before, .menu-item.active a::before {
  color: white;
} */
.menu-item:hover .sub-menu {
  transform: rotateX(0deg);
}
.menu-item:hover .sub-main-div2 {
  transform: rotateX(0deg);
}
.menu-item .sub-menu > li:first-child .sub-main-div {
  transform: rotateX(0deg);
}
.menu-item .sub-menu > li:hover .sub-main-div {
  transform: rotateX(0deg);
  display: block;
}

.first-sub-menu:hover .sub-main-div > .left-div {
  transform: rotateX(0deg);
}
.menu-item .sub-menu > li:hover .sub-material-icons {
  display: block;
}
/* .closeNavbar {
  transform: rotateX(-90deg);
} */
@media (min-width: 862px) {
  .header-container {
    padding: 30px 1rem;
  }
}
@media (max-width: 862px) {
  .header-container {
    margin-left: 2%;
  }
  .login-container {
    display: none !important;
  }
  .login_container_mobile {
    display: block;
  }
  .menu-item .sub-menu {
    opacity: 1;
  }
  .sub-main-div {
    display: none;
    position: static;
    padding: 0px;
    width: auto;
    height: auto;
    background-color: transparent;
    border: none;
  }
  .sub-main-div .left-div {
    background-color: transparent;
    display: block;
    transform: rotateX(-90deg);
    margin-bottom: 30px;
  }

  .superSubMenu p {
    color: white;
  }

  /* .first-sub-menu:hover .sub-main-div > .left-div {
    display: block;
  } */
  .sub-main-div .left-div > a {
    padding: 0px;
  }
  .menu-item:hover .sub-menu {
    position: relative;
    inset: 0;
    background-color: black;
    flex-direction: column;
  }
  .menu-item ol.sub-menu {
    padding: 0;
    height: auto;
    line-height: 19px;
  }
  .drop-icon-flex {
    padding: 0;
  }
  .drop-left {
    padding: 0;
  }
  .drop-right {
    padding: 0;
  }
  .active {
    align-items: flex-start;
  }
  .drop-left-heading,
  .drop-right-heading {
    margin: 4px 0;
    font-size: 12px;
  }
  .description {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .description:last-child {
    margin-bottom: 2px;
  }
  .menu-item {
    flex-direction: column;
    align-items: flex-start;
  }
  .menu-item .sub-menu {
    opacity: 1;
  }

  .drop-right {
    background-color: inherit;
  }
  .menu {
    position: fixed;
    top: 0%;
    right: -100%;
    height: 100%;
    width: 100%;
    background: #000;
    transition: all 0.6s ease-in-out;
  }
  .menu ol:first-child {
    flex-direction: column;
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 10px;
    margin-top: min(10vh);
    padding-left: 2rem;
  }
  span.nav-bottom-line {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  .menu-item a {
    color: white;
  }
  .menu-in {
    z-index: 999;
    left: 30%;
  }

  .close-menu {
    left: 100% !important;
  }
  .scroll-lock {
    overflow: hidden;
  }
  span.nav--heading {
    color: white;
    text-align: left;
    font-weight: normal;
  }
  a.nav-bottom-line {
    font-weight: bold;
    font-size: 15px;
  }
  .menu-btn {
    position: absolute;
    z-index: 1000;
    right: 20px;
    /*left: 20px; */
    top: 20px;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .menu-btn span,
  .menu-btn:before,
  .menu-btn:after {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    left: 30%;
    width: 40%;
    border-bottom: 2px solid black;
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .menu-btn:before {
    transform: translateY(-8px);
  }
  .menu-btn:after {
    transform: translateY(8px);
  }

  .close-btn {
    position: absolute;
    z-index: 1000;
    right: 20px;
    top: 20px;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .close-btn:before,
  .close-btn:after {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    left: 30%;
    width: 40%;
    border-bottom: 2px solid white;
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .close-btn:before {
    transform: translateY(-8px);
    transform: rotate(134deg);
  }
  .close-btn:after {
    transform: translateY(8px);
    transform: rotate(51deg);
  }

  .close {
    z-index: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: background 0.6s;
  }
  /* closing animation */
  #active:checked + .menu-btn span {
    transform: scaleX(0);
  }
  #active:checked + .menu-btn:before {
    transform: rotate(45deg);
    border-color: #fff;
  }
  #active:checked + .menu-btn:after {
    transform: rotate(-45deg);
    border-color: #fff;
  }

  .menu ol li {
    height: 7%;
    /* margin: 15px 0; */
  }
  .menu ol li a {
    /* color: #fff; */
    letter-spacing: 1px;
    font-size: 16px;
    transition: all 0.3s ease;
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .menu-item a.nav-bottom-line:hover::after,
  .menu-item span.nav-bottom-line:hover::after {
    display: none;
  }

  .menu ol li a:hover:after {
    transform: scaleY(1);
  }
  .menu ol li a:hover {
    color: #1a73e8;
  }

  #active:checked ~ .menu ol li a {
    opacity: 1;
  }
  .menu ol li a {
    transition: opacity 1.2s, transform 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    font-weight: bold;
    font-size: 13px;
    color: white;
    margin-top: 5px;
  }
  #active:checked ~ .menu ol li a {
    transform: none;
    transition-timing-function: ease, cubic-bezier(0.1, 1.3, 0.3, 1); /* easeOutBackを緩めた感じ */
    transition-delay: 0.3s;
  }
  .sub-main-div2 {
    background-color: inherit;
    color: white;
    display: block;
    margin: 0;
    padding: 0;
    width: fit-content;
    height: 100%;
    top: 0;
  }
  .sub-main-div2 p:nth-child(2) {
    display: none;
  }
  .sub-main-div2 img {
    width: 28px;
  }
  .drop-icon-flex {
    padding: 9px 0px;
  }
  .sub-main-div2 h4 {
    font-size: 13px !important;
  }
  .menu-item:hover .sub-main-div2 {
    position: relative;
  }
}

@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 17px;
    font-weight: inherit;
  }

  .header-container {
    padding: 30px 12rem;
  }
}
