.framer_shadow_container {
  padding: 8px;
  background-color: white;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
}
.framer_container {
  max-width: 366px;
  width: 100%;
  height: 420px;
  padding: 1rem;
  border-radius: 1rem;
  background: rgb(30, 30, 30);
}

/* styling tabs here */
.tablist {
  display: flex;
  gap: 1rem;
  padding-left: 0px;
}

.tab {
  list-style: none;
  cursor: pointer;
  color: #999;
  padding: 0px 8px;
  border-radius: 5px;
  font-weight: bold;
}
.tab:hover {
  color: rgb(240, 240, 240);
}
.selected_tab {
  color: white;
  background-color: #5e6ff9;
  outline: none;
}

@media (min-width: 720px) {
  .framer_container {
    max-width: 536px;
    width: 536px;
    padding: 2rem;
  }
}
@media (min-width: 1020px) {
}
