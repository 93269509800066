.slick-list {
  padding: 1rem;
  margin: 0px auto;
  /* padding-left: 74px; */
  /* padding-left: 11%; */
  /* background-image: url('/src/components/assets/bgimage/bg3.png'); */
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
}
.slick-slider {
  overflow: hidden;
  padding-bottom: 2em;
}
.slick-dots {
  height: 58px;
}
/* .slick-arrow {
  background-color: black;
} */
.slick-prev {
  left: 4%;
}
.slick-next {
  right: 4%;
}
.slick-list,
.slick-track {
  /* width: 700px; */
}
/* .slick-track {
  display: flex;
  align-items: center;
  justify-content: center;
} */
