.image_fluid {
  max-width: 100%;
  height: auto;
}

.hero_container {
  background-image: url("../../../assets/images/bg1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  position: relative;
}

.main_container {
  max-width: 1200px;
  margin: 0 auto;
}
.text_container {
  color: black;
  text-align: center;
  max-width: 503px;
}

.text_container > h1 {
  font-size: 2rem;
  color: #5e6ff9;
  margin: 1rem 0px;
  /* animation: slideInLeft 1s ease-in-out; */
  margin-bottom: 3rem;
}
.text-container .button {
  text-decoration: none;
  border: 2px solid #0089ff;
  border-radius: 8px;
  padding: 12px 16px;
  color: #0089ff;
  font-weight: 700;
  min-width: 160px;
  display: inline-block;
}
.button:hover {
  background-color: #e5efff;
}
.text_container > p {
  /* animation: slideInRight 1s ease-in-out; */
  font-size: 18px;
}
.flex_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.company_partner {
  width: 100%;
  height: 100px;
  text-align: center;
  overflow: hidden;
}

.image_column img {
  width: 100%;
  max-width: 600px;
  height: auto;
}
.slick-arrow,
.slick-next {
  display: none;
}

.btn {
  border: 2px solid #5e6ff9;
  border-radius: 20px;
  padding: 10px 28px;
  font-size: 16px;
  width: 180px;
  cursor: pointer;
}
.btn:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.success1 {
  color: white;
  background-color: #5e6ff9;
}
.success2 {
  color: #5e6ff9;
  background-color: white;
}

.btns_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}



.company_logo img {
  width: 150px;
}

.slick-track {
  display: flex;
  align-items: center;
}

@media (min-width: 716px) {
  .hero_container {
    background-size: cover;

    height: 62vh;
    width: 100%;
  }
  .company_partner {
    bottom: 42px;
  }
  .text_container {
    top: 0px;
    text-align: left;
    padding: 6vh 0px;
  }
  .btns_wrapper {
    flex-direction: row;
    justify-content: flex-start;
  }
}

@media (min-width: 1020px) {
  .flex_container {
    flex-direction: row;
    justify-content: space-between;
  }
  .text_container {
    left: 13%;
    margin-left: 3rem;
  }
  .text_container > h1 {
    font-size: 2.7rem;

    font-weight: 500 !important;
  }
  .hero_container {
    height: 750px;
    background-image: url("../../../assets/images/homebg1.webp");
  }
}

/* @keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
} */
