:root {
  --primary-color: #5e6ff9;
}

p {
  font-size: 18px;
}

.scroll-to-top {
  border-radius: 50%;
  -webkit-box-shadow: 15px 15px 15px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 15px 15px 15px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 75%);
}
