.about-tc {
    background-color: #6134e0;
}

/***********************slider**************************/
#slideset  {
    height: 100vh;
    position: relative;
    overflow: hidden;
}
#slideset > * {
    width: 100%;
    height: inherit;
    position: absolute;
    left: 0;
    top:0;
    /* animation: 8s autoplay6 infinite linear; */
}

#slideset > div:nth-child(1) {
    animation-delay: 0s;
}
#slideset > div:nth-child(2) {
    animation-delay: 4s;
}
@media (min-width: 720px)  {
    #slideset  {
        /* height: 610px; */
        position: relative;
        overflow: hidden;
    }
}


@keyframes autoplay6 {
    0% {opacity: 0.0}
    4% {opacity: 1.0}
    50% {opacity: 1.0}
    57% {opacity: 0.0}
    100% {opacity: 0.0}
  }
