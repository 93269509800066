.hero_container {
  background-image: url("../../assets/images/bg2.webp");
  padding: 70px 15px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 111%;
}
.main_container {
  margin: 0 auto;
  text-align: center;
}
.text_container {
  color: black;
  text-align: center;
  flex-basis: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text_container > h5 {
  font-size: 17px;
  color: black;
}

.text_container > h1 {
  font-size: 2.8rem;
  margin-top: 24px;
  margin-bottom: 12px;
}
.iconChannel img {
  width: 80px;
}
.iconChannel img:hover {
  /* box-shadow: #5e6ff9 0px 3px 8px; */
}
.iconChannel {
  display: flex;
  gap: 2rem;
}
.text_container > p {
  font-size: 18px;
  line-height: 30px;
  color: #273746;
}
.flex_container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.flex_container > div {
  gap: 2rem;
}
.image_column {
  
  background-repeat: no-repeat;
}

.image_column img {
  max-width: 230px;
  width: 100%;
  height: auto;
  margin-top: 0px;
  padding: 17px 1px 0px 4px;
}

.test_container {
  display: flex;
  flex-direction: column;
  max-width: 306px;
  gap: 1rem;
  margin-top: 17px;
  text-align: center;
}
.test_container p {
  margin-bottom: 0px;
}

.test_input {
  border-bottom: 1px solid #5e6ff9 !important;
}

@media (min-width: 720px) {
  .hero_container {
    padding: 60px 3rem;
    margin-top: -7%;
  }
}

@media (min-width: 1020px) {
  .main_container {
    text-align: left;
  }

  .flex_container {
    flex-direction: row;
  }
  .text_container {
    text-align: left;
  }
  .hero_container {
    padding: 3rem 4rem;
    margin-top: -2%;
  }
  .iconChannel img {
    width: 100px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  }
  .image_column img {
    max-width: 280px;
  }
}
