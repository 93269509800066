* {
  margin: 0;
  padding: 0;
}
.contact-flex-container {
  /* padding-top: 3px; */
  /* padding-bottom: 10px; */
  display: flex;
  /* max-width: 100%; */
  justify-content: space-around;
  align-items: center;
  /* gap: 1%; */
  /* background-repeat: repeat; */
  /* background-size: cover; */
  flex-direction: row;
  /* height: auto; */
  /* background-position: center; */
  margin-top: 2rem;
}

.top-icon {
  background-position: 25% 75%;
  position: absolute;
  z-index: 1;
  left: 38%;
  max-width: 85px;
  padding-top: 2%;
}

.large-heading-1 {
  font-weight: 900;
}

.top-arrow {
  max-width: 7rem;
  background-position: 25% 75%;
  position: absolute;
  z-index: 1;
  margin-left: 2%;
}
.contact-left-image img {
  max-width: 13rem;
  height: auto;
  font-family: "Poppins", sans-serif;
  margin-top: 20%;
  margin-bottom: 1rem;
}
.contact-form {
  /* width: 35%; */
  /* font-weight: 1200px; */
  margin-top: 40px;
  font-family: "Poppins", sans-serif;
}

.special_button {
  position: absolute;
  left: 100%;
}

.bottom_image {
  position: absolute;
  bottom: 0px;
  height: 110px;
}

.sticky-button.enabled {
  opacity: 1;
  cursor: pointer;
  background-color: black;
}

.input-field:focus {
  color: black;
}

.input-type {
  font-size: large;
  word-spacing: 3px;
  font-family: "Poppins", sans-serif;
}

/* .loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid blue;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 25px;
  animation: spin 5s linear infinite; */

/* @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */
.text-center {
  font-family: "Poppins", sans-serif;
}

.input-field {
  width: 100%;
  padding: 11px 18px;
  font-size: 14px;
  position: relative;
  border: none;
  background-color: inherit;
  border-radius: 9px;
  border: 1px solid grey;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}
.input-field:focus {
  border-bottom-color: black;
  font-family: "Poppins", sans-serif;
}
.input-otp:focus {
  border-bottom-color: black;
}
.label {
  display: block;
  margin-bottom: 0.5rem;
}
select {
  color: black;
}
option {
  color: black;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
.input-field::placeholder .input-field,
.contact-form,
.contact-form p {
  color: black;
}
.input-field::placeholder {
  color: grey;
}
.form-control {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(3, 1fr));
  gap: 1rem;
  margin-top: 3rem;
}
.textarea {
  position: absolute;
  grid-column: 1/-1;
  height: 100%;
  background-color: inherit;
  border: none;
  color: black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 50px;
  padding: 8px;
  cursor: pointer;
}
.custom-shape-divider-bottom-1666093673 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1666093673 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1666093673 .shape-fill {
  fill: #ffffff;
}
input[type="submit"] {
  color: white;
  background-color: #5e6ff9;
  font-size: 12px;
  padding: 11px;
  letter-spacing: 0.09em;
  width: 13rem;
  border: none;
  border-radius: 2px;
}

input[type="submit"]:hover {
  cursor: pointer;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.phone-input {
  font-size: 12px;
  border: 1px solid #5e6ff9;
  border-radius: 28px;
  width: 100%;
  outline: none;
}
.react-tel-input .form-control {
  width: inherit;
  background-color: inherit;
  font-size: 14px;
  padding: 14px 14px 10px 60px;
  color: black;
  border: none;
  border-radius: 50px;
  width: 100%;
  border: 1px solid #5e6ff9;
}
.react-tel-input .form-control:focus {
  background-color: inherit;
  box-shadow: none;
}
.contact-form #dropdown {
  padding: 14px;
  margin-top: 2%;
}
/* .modal-pointer:focus-visible{
  background-color: blue;
}  */

.input-otp :hover::after {
  background-color: blue;
}

.modal-body > h2 > div {
  justify-content: center;
}
.btn-link:focus {
  background-color: none !important;
}
#get-in-touch {
  color: #5e6ff9;
}
.signup-heading {
  font-weight: lighter !important;
}
.signup-hello {
  font-weight: bolder;
}
.signup-stay {
  font-weight: lighter;
  word-spacing: 0.3rem;
  height: 1rem;
}
.country_phone input {
  border: 1px solid grey !important;
  border-radius: 9px !important;
  height: 45px !important;
  width: 100% !important;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border-radius: 3px 0 0 3px;
}
.bottom-image {
  flex-direction: column;
  border: 0px;
  position: absolute;
  grid-column: 1/-1;
}

.checkbox {
  grid-column: 1/-1;
  margin-top: -0.8rem;
  text-align: left;
}

form {
  display: grid;
  align-items: baseline;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  gap: 1rem;
  /* margin-top: 3rem; */
}


.signup_container {
  /* position: relative; */
  background-image: url("../assets/images/bg4.webp");
  height: 100vh;
  padding: 30px 16px;
  background-position: center;
  background-size: cover;
}
.text_wrapper :not(strong) {
  font-weight: light;
  margin: 0px;
}

.text_wrapper p{
  margin-bottom: 16px;
  color: grey;
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.material-symbols-outlined {
  cursor: pointer;
}
.my-modal1 {
  --bs-modal-border-radius: 1.5rem;
}

.modal .modal-header {
  --bs-modal-header-border-color: white !important;
  --bs-modal-footer-border-color: white !important;
}

element.style {
  width: 2.5em;
  text-align: center;
}

.modal-footer {
  padding: 0%;
}

@media only screen and (max-width: 1040px) {
  input[type="submit"] {
    margin: 0 auto;
  }
  .contact-form {
    text-align: left;
    margin-top: auto;
    width: 100%;
  }
  .signup_container {
    height: 100%;
    bottom: 0px;
  }
  .bottom_image {
    position: absolute;
    flex-direction: column;
    height: 85px;
    bottom: 0px;
  }

  .contact-flex-container {
    display: grid;
    flex-direction: column;
    align-items: center;
    background-position-y: bottom;
    background-size: 100vh;
    max-width: 100%;
    max-height: 100%;
    margin: 5px;
  }
  .input[type="submit"] {
    margin: 0 auto;
    align-items: center;
    flex-direction: column;
  }
  .contact-form {
    display: grid;
    flex-direction: column;
    margin-top: 1rem;
    /* row-gap: 5px; */
  }
  .check-box {
    align-items: center;
    max-width: fit-content;
  }
  .form {
    flex-direction: column;
    max-width: fit-content;
    gap: 1rem;
  }
  .top-arrow {
    max-width: 60px;
    flex-direction: column;
  }
  .top-icon {
    flex-direction: column;
    position: absolute;
    max-width: 77px;
    left: 70%;
  }
  .contact-left-image img {
    flex-direction: column;
  }
  .contact-left-image h3 {
    flex-direction: column;
  }
  .contact-left-image h1 {
    flex-direction: column;
  }
  .bottom-image {
    display: grid;
    flex-direction: column;
    /* height: 80px; */
    position: absolute;
  }
}

@media only screen and (max-width: 280px) {
  .contact-flex-container {
    margin-left: 30px;
    margin-right: 4px;
    padding-left: 10px;
    padding-right: 2px;
  }
  .bottom-image {
    height: 5px;
  }
  .contact-left-image h3 {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1280px) {
  .SignUp_bottom_image__IsKY5 {
    position: absolute;
    bottom: -60px !important;
    height: 180px;
  }
}
@media only screen and (max-width: 1280px) {
  .Button {
    width: 180px;
    justify-content: flex-end;
    display: grid;
    margin-top: -2rem;
  }
}

@media only screen and (max-width: 760px) {
  .contact-left-image img {
    width: 15rem;
  }
}
