.hero_container {
  background-color: white;
  background-image: url("/src/components/assets/images/bg4.webp");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px 15px;
  /* color: white; */
}
.uppcase {
  text-align: center;
  text-transform: uppercase;
}
.hero_container > p {
  font-size: 22px;
}
.flex_container {
  /* padding: 60px 15px; */
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
}
.flex_item {
  flex: 1;
}
.flex_item:nth-child(2) {
  display: grid;
  place-items: center;
}
.flex_item > h1 {
  font-size: 38px;
  text-align: center;
}
.flex_item > p {
  margin-top: 1rem;
  margin-bottom: 3rem;
  font-size: 18px;
  text-align: center;
}
.img_tag {
  width: 30px;
}

.globe_image {
  width: 100%;
  /* max-width: 350px; */
  max-width: 240px;
  height: fit-content;
  margin-top: 1.5rem;
  /* padding: 24px; */
}
.flex_container2 {
  display: flex;
  align-items: center;
  /* padding-bottom: 1.3rem; */
  gap: 2rem;
}
.text_wrapper {
  width: 100%;
}
.text_wrapper > p {
  line-height: 1.6em;
}
.text_wrapper > h3 {
  margin-bottom: -9px;
  color: #5e6ff9;
}
.text_wrapper img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin: 8px;
}
.text_wrapper a {
  font-weight: bold;
  color: #5e6ff9;
  font-size: 18px;
}

@media (min-width: 720px) {
  /* .flex_container {
        padding: 60px 3rem;
        
    } */
}
@media (min-width: 1020px) {
  .flex_container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  .flex_item > h1,
  .flex_item > p {
    text-align: left;
  }
  .flex_item:nth-child(1) {
    text-align: right;
  }
  .flex_item:nth-child(3) {
    text-align: left;
  }
  .globe_image {
    max-width: 280px;
  }
}
