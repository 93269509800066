.hero_container h2 {
  color: #5e6ff9;
}

.image_column img {
  max-width: 700px;
  width: 100%;
  height: auto;
}
.wehelp_container {
  display: grid;
  grid-template-rows: auto auto auto auto auto;
  /* gap: 6px; */
  text-align: left;
}
.wehelp_item {
  padding: 1rem;
  color: white;
  text-align: center;
}

.wehelp_item > h2 {
  font-size: 2.5rem;
  color: #5e6ff9;
}
.wehelp_item > p {
  font-size: 18px;
  color: #112446;
}

.wehelp_item > p {
  margin: 10px 0;
  color: black;
}

@media (min-width: 720px) {
  .hero_container {
    /* padding: 60px 3rem; */
  }
  .wehelp_container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1020px) {
  .wehelp_container {
    grid-template-columns: repeat(4, 1fr);
    margin: 2rem 0px;
  }
  .hero_container {
    /* padding: 0px 3rem; */
  }
  .main_container {
    text-align: left;
  }
  .wehelp_item > h2 {
    font-size: 3.5rem;
  }
}
