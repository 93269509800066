.footer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 30px;
  padding-top: 3rem;
  color: #687992;
}
.card-wrapper {
  display: grid;
  font-size: 15px;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  margin: 2px auto;
  width: 100%;
}
.fnt-size {
  font-size: 17px;
  color: black;
}
ul.link {
  display: grid;
  padding-left: 0px;
}

.sm-card1 {
  text-align: center;
  margin-top: 50px;
  display: block;
}
.sm-card1 a {
  text-decoration: none;
  color: black !important;
  font-size: 14px;
}
.sm-card1 p {
  margin: 0;
}
.f-header {
  text-align: center;
  margin-bottom: 3rem;
  color: black;
}
.card-wrapper section {
  line-height: 24px;
  text-align: center;
}
.card-wrapper section:first-child {
  grid-column: 1/-1;
  margin-right: auto;
}

.card-wrapper section:last-child {
  /* justify-self: center; */
}

img.brand {
  max-height: 4rem;
  padding-top: 5px;
}
.copyright {
  margin-top: 1rem;
}
.copyright p {
  text-align: center;
  font-size: 12px;
}

section:last-child ul {
  list-style: none;
}

.social-links p {
  font-size: 14px;
  text-align: center;
}

.card-wrapper .link li a {
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;
  display: block;
  width: fit-content;
  cursor: pointer;
  font-size: 13px;
  color: black;
}
.link li a,
.link li a:after {
  transition: all 0.3s;
}
.media-icon {
  width: 26px;
  height: auto;

  /* margin: 0 5px; */
}

.footer-container section h3 {
  color: #5e6ff9;
}
.footer-container section ul {
  list-style: none;
}

.media-icon:hover {
  background-color: white;
}

.media {
  margin-top: 7px;
  display: flex;
  gap: 4px;
  margin-right: auto;
}
.meta-container {
  gap: 7px;
  padding: 4px 0px;
  display: flex;
}
.meta-container img:nth-child(1) {
  width: 74px;
  height: auto;
}

.meta-container img:nth-child(2) {
  width: 88px;
  height: auto;
}

.brand_container {
  flex-direction: column;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-bottom: 2rem;
}

@media (min-width: 625px) {
  .card-wrapper section:last-child {
    justify-self: start;
  }

  .card-wrapper section {
    text-align: left;
  }
  ul.link {
    display: block;
  }
}

@media (min-width: 910px) {
  .card-wrapper section:last-child {
    /* justify-self: end; */
  }
  .brand_container {
    align-items: flex-start;
  }
  .footer-container {
    padding: 4rem 3rem;
    padding-bottom: 2rem;
  }
  .card-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .card-wrapper section:first-child {
    grid-column: 1;
    margin-right: auto;
  }
}

/* Keyframes */
@keyframes circle {
  0% {
    width: 1px;
    left: -100%;
    right: -100;
    margin: auto;
    height: 1px;
    z-index: -1;
    background: #eee;
    border-radius: 100%;
  }
  100% {
    background: black;
    height: 5000%;
    width: 5000%;
    z-index: -1;
    left: -100%;
    right: 100%;
    margin: auto;
    border-radius: 0;
  }
}
